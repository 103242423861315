import styled from 'styled-components';
import blueBackground from '../../../images/background/bg-blue.png';
import ceraBackground from '../../../images/background/bg-cera.png';
import charcoalBackground from '../../../images/background/bg-charcoal.png';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const QuoteStyles = styled.div`
  padding: 48px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${atMinWidth.md`
    padding: 72px 96px;
  `}

  &.testimonial-light {
    background-color: var(--white);
  }

  &.testimonial-cera {
    background-image: url(${ceraBackground});
  }

  &.testimonial-blue {
    background-image: url(${blueBackground});
  }

  &.testimonial-charcoal {
    background-image: url(${charcoalBackground});
  }

  &.testimonial-blue,
  &.testimonial-charcoal {
    .message,
    .person .role,
    .person .name {
      color: var(--white);
    }
  }

  .profile-picture {
    margin: 0 auto 32px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    img {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .message {
    max-width: 970px;
    ${font('display', 'xs', '600')}
    color: ${colors.gray[900]};
    text-align: center;
    margin: 0 24px 24px 24px;

    ${atMinWidth.md`
      ${font('display', 'md', '600')}
    margin: 0 32px 32px 32px;
    `}

    ${atMinWidth.lg`
      margin: 0 0 32px 0;
    `}

    strong {
      color: #1c3fff;
      font-weight: 600;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      font-size: 22.5px;
      line-height: 110%;
    }
  }

  .person-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${font('text', 'sm', '500')}
    color: ${colors.gray[900]};
    text-align: center;

    ${atMinWidth.md`
      ${font('text', 'md', '500')}
    `}

    ${atMinWidth.lg`
      ${font('text', 'lg', '500')}
    `}

    .name-container {
      display: flex;
      flex-direction: row;
    }

    .name {
      font-weight: 600;
      margin: 0;
    }

    .role {
      font-weight: 400;
      margin: 0;
    }
  }

  .person {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${font('text', 'sm', '500')}
    color: ${colors.gray[900]};
    text-align: center;

    ${atMinWidth.sm`
      flex-direction: row;
    `}

    ${atMinWidth.md`
      ${font('text', 'md', '500')}
    `}

    ${atMinWidth.lg`
      ${font('text', 'lg', '500')}
    `}

    .name {
      font-weight: 600;
      margin: 0;
    }

    .role {
      font-weight: 400;
      margin: 0;
    }
  }
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  margin-bottom: 32px;
  color: ${props =>
    props.color || props.lightPrint ? colors.base.white : colors.primary[500]};
`;

export const StarRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
